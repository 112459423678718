import React, { useEffect, useState } from "react";
import "./dashboard.css";
import axiosInstance from "../../../helpers/axiosInstance";
import { toast } from "react-toastify";
import Loading from "../../../shared/loading/Loading";
import AuthorizedContent from "../../../shared/AuthorizedContent";
import { ReactComponent as ArrowIcon } from "../../../assets/signin/right.svg";
import { AnimatePresence, motion } from "framer-motion";

function Dashboard(props) {
  const [organisationData, setOrganisationData] = useState({});

  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [total_savings, settotal_savings] = useState(organisationData.total_saving_amount);
  const [total_members, settotal_members] = useState(organisationData.total_members);
  const [gained_interest, setgained_interest] = useState(organisationData.total_interest_gained);
  const [numberof_loans, setnumberof_loans] = useState(organisationData.total_number_loan_approved);
  const [approved_loans, setapproved_loans] = useState(organisationData.total_loan_approved);
  const [pending_loan, setpending_loan] = useState(organisationData.pending_loan_amount);
  const [present_balance, setpresent_balance] = useState(organisationData.present_balance);
  const [present_interestbalance, setpresent_interestbalance] = useState(organisationData.present_interest_balance);
  const [voucher_credited, setVoucher_credited] = useState(organisationData.voucher_credited);
  const [voucher_debited, setVoucher_debited] = useState(organisationData.voucher_debited);
  const [your_saving_amount, setyour_saving_amount] = useState(organisationData.your_saving_amount);
  const [pendingLoansApprovals, setPendingLoansApprovals] = useState(organisationData.pending_loans_approvals);

  const [completedUserData, setCompletedUserData] = useState([]);
  const [pendingUserData, setPendingUserData] = useState([]);
  const [statusOption, setStatusOption] = useState("pending");

  useEffect(() => {
    getOrganisation();
    getPaymentStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPaymentStatus = () => {
    axiosInstance
      .get(`/api/analytics/${props.organisation._id}/getsavingstatus`)
      .then((response) => {
        console.log(response.data);
        setCompletedUserData(response.data.data.completedUsers);
        setPendingUserData(response.data.data.pendingUsers);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get saving transactions details. Try again!");
        console.log(error);
      });
  };

  const getOrganisation = () => {
    axiosInstance
      .get(`/api/organisation/getorganisationbyid/${props.organisation._id}`)
      .then((response) => {
        setOrganisationData(response.data.data);
        settotal_savings(response.data.data.total_saving_amount);
        settotal_members(response.data.data.total_members);
        setgained_interest(response.data.data.total_interest_gained);
        setnumberof_loans(response.data.data.total_number_loan_approved);
        setapproved_loans(response.data.data.total_loan_approved);
        setpending_loan(response.data.data.pending_loan_amount);
        setpresent_balance(response.data.data.present_balance);
        setyour_saving_amount(response.data.data.your_saving_amount);
        setpresent_interestbalance(response.data.data.present_interest_balance);
        setVoucher_credited(response.data.data.voucher_credited);
        setVoucher_debited(response.data.data.voucher_debited);
        setPendingLoansApprovals(response.data.data.pending_loans_approvals);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get saving transactions details. Try again!");
        console.log(error);
      });
  };
  const handleSubmit = () => {
    if (!total_savings && !total_members && !gained_interest && !numberof_loans && !approved_loans && !pending_loan) {
      toast("Please fill all the required fields.");
    } else {
      axiosInstance
        .post(`/api/organisation/upsertorganisation`, {
          ...organisationData,
          total_members: parseFloat(total_members),
          total_loan_approved: parseFloat(approved_loans),
          total_number_loan_approved: parseFloat(numberof_loans),
          pending_loan_amount: parseFloat(pending_loan),
          total_saving_amount: parseFloat(total_savings),
          total_interest_gained: parseFloat(gained_interest),
          present_balance: parseFloat(present_balance),
          your_saving_amount: parseFloat(your_saving_amount),
          present_interest_balance: parseFloat(present_interestbalance),
          voucher_credited: parseFloat(voucher_credited),
          voucher_debited: parseFloat(voucher_debited),
          pending_loans_approvals: parseFloat(pendingLoansApprovals),
        })
        .then((response) => {
          getOrganisation();
          setModal(false);
          toast("Updated Organisation details.");
        })
        .catch((error) => {
          toast("Unable to edit organisation details. Try again!");
          console.log(error);
        });
    }
  };
  const refreshOrganisation = () => {
    axiosInstance
      .get(`/api/organisation/refreshorganisation/${props.organisation._id}`)
      .then((response) => {
        getOrganisation();
        toast("Refreshed Organisation details.");
      })
      .catch((error) => {
        toast("Unable to refresh organisation details. Try again!");
        console.log(error);
      });
  };

  const renderDetailModal = () => {
    return (
      <motion.section
        className="dashboard-modal-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.2 }}
      >
        <header className="dashboard-modal-header">
          <ArrowIcon className="dashboard-modal-lefticon" onClick={() => setModal(false)} />
          Edit Organisation Details
        </header>
        <main className="dashboard-modal-body">
          <motion.div className="dashboard-form">
            <label>Total Savings</label>
            <input
              type="text"
              name="amount"
              onChange={({ target }) => {
                settotal_savings(target.value);
              }}
              value={total_savings || ""}
            />
          </motion.div>
          <div className="dashboard-form">
            <label>Present Balance</label>
            <input
              type="text"
              name="amount"
              onChange={({ target }) => {
                setpresent_balance(target.value);
              }}
              value={present_balance || ""}
            />
          </div>
          <div className="dashboard-form">
            <label>Your Saving Amount</label>
            <input
              type="text"
              name="amount"
              onChange={({ target }) => {
                setyour_saving_amount(target.value);
              }}
              value={your_saving_amount || ""}
            />
          </div>

          <div className="dashboard-form">
            <label>Total members</label>
            <input
              type="text"
              name="amount"
              onChange={({ target }) => {
                settotal_members(target.value);
              }}
              value={total_members || ""}
              required={true}
            />
          </div>
          <div className="dashboard-form">
            <label>Gained Interest</label>
            <input
              type="text"
              name="term"
              onChange={({ target }) => {
                setgained_interest(target.value);
              }}
              value={gained_interest || ""}
              required={true}
            />
          </div>
          <div className="dashboard-form">
            <label>Present Interest Balance</label>
            <input
              type="text"
              name="term"
              onChange={({ target }) => {
                setpresent_interestbalance(target.value);
              }}
              value={present_interestbalance || ""}
              required={true}
            />
          </div>
          <div className="dashboard-form">
            <label>Voucher Credited</label>
            <input
              type="text"
              name="term"
              onChange={({ target }) => {
                setVoucher_credited(target.value);
              }}
              value={voucher_credited || ""}
              required={true}
            />
          </div>
          <div className="dashboard-form">
            <label>Voucher Debited</label>
            <input
              type="text"
              name="term"
              onChange={({ target }) => {
                setVoucher_debited(target.value);
              }}
              value={voucher_debited || ""}
              required={true}
            />
          </div>
          <div className="dashboard-form">
            <label>Total Number of Loans</label>
            <input
              type="text"
              name="interest"
              onChange={({ target }) => {
                setnumberof_loans(target.value);
              }}
              value={numberof_loans || ""}
              required={true}
            />
          </div>
          <div className="dashboard-form">
            <label>Approved Loan Amount</label>
            <input
              type="text"
              name="interest"
              onChange={({ target }) => {
                setapproved_loans(target.value);
              }}
              value={approved_loans || ""}
              required={true}
            />
          </div>
          <div className="dashboard-form">
            <label>Pending Loan Amount</label>
            <input
              type="text"
              name="interest"
              onChange={({ target }) => {
                setpending_loan(target.value);
              }}
              value={pending_loan || ""}
              required={true}
            />
          </div>
          <div className="dashboard-form">
            <label>Pending Loan Approvals</label>
            <input
              type="text"
              name="interest"
              onChange={({ target }) => {
                setPendingLoansApprovals(target.value);
              }}
              value={pendingLoansApprovals || ""}
              required={true}
            />
          </div>
        </main>
        <footer className="dashboard-modal-footer">
          <button className="dashboard-addbtn" onClick={handleSubmit}>
            Submit
          </button>
        </footer>
      </motion.section>
    );
  };

  let date = new Date(organisationData.start_date).toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div className="dashboard-container">
      <AnimatePresence>{modal ? renderDetailModal() : null}</AnimatePresence>
      <div className="home-header">
        <div className="home-headerleft">
          <h3 className="home-heading">Dashboard</h3>
        </div>
        <AuthorizedContent roles={["super-admin", "admin"]}>
          <button className="dashboard-addbtn" onClick={() => refreshOrganisation()}>
            Refresh
          </button>
          <button className="dashboard-addbtn" onClick={() => setModal(true)}>
            Edit
          </button>
        </AuthorizedContent>
      </div>
      {loading ? (
        <div className="dashboard-loader-section">
          <Loading />
        </div>
      ) : (
        <motion.div className="dashboard-header" initial={{ opacity: 0, x: -50 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.3 }}>
          <p>{organisationData.name || "-"}</p>
          <label>
            <span>Established date : </span> {date || "-"}
          </label>
        </motion.div>
      )}
      <section className="dashboard-topsection">
        <motion.div className="dashboard-topblock" initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }}>
          <p className="dashboard-topblock-data">₹{organisationData.total_saving_amount || "0"}</p>
          <label className="dashboard-topblock-label">Total Savings</label>
        </motion.div>
        <motion.div
          className="dashboard-topblock"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.1 }}
        >
          <p className="dashboard-topblock-data">₹{organisationData.present_balance || "0"}</p>
          <label className="dashboard-topblock-label">Present Balance</label>
        </motion.div>
        <motion.div
          className="dashboard-topblock"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.2 }}
        >
          <p className="dashboard-topblock-data">₹{organisationData.your_saving_amount || "0"}</p>
          <label className="dashboard-topblock-label">Your Saving Amount</label>
        </motion.div>
        <motion.div
          className="dashboard-topblock"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.3 }}
        >
          <p className="dashboard-topblock-data">{organisationData.total_members || "0"}</p>
          <label className="dashboard-topblock-label">Total Members</label>
        </motion.div>

        <motion.div
          className="dashboard-topblock"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.4 }}
        >
          <p className="dashboard-topblock-data">₹{organisationData.total_interest_gained || "0"}</p>
          <label className="dashboard-topblock-label">Gained intrest</label>
        </motion.div>
        <motion.div
          className="dashboard-topblock"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.4 }}
        >
          <p className="dashboard-topblock-data">₹{organisationData.present_interest_balance || "0"}</p>
          <label className="dashboard-topblock-label">Present Intrest Balance</label>
        </motion.div>
        <motion.div
          className="dashboard-topblock"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.4 }}
        >
          <p className="dashboard-topblock-data">₹{organisationData.voucher_debited || "0"}</p>
          <label className="dashboard-topblock-label">Voucher Debited</label>
        </motion.div>
        <motion.div
          className="dashboard-topblock"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.4 }}
        >
          <p className="dashboard-topblock-data">₹{organisationData.voucher_credited || "0"}</p>
          <label className="dashboard-topblock-label">Voucher Credited</label>
        </motion.div>

        <motion.div
          className="dashboard-topblock"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.5 }}
        >
          <p className="dashboard-topblock-data">{organisationData.total_number_loan_approved || "0"}</p>
          <label className="dashboard-topblock-label">Total No of Loans</label>
        </motion.div>
        <motion.div
          className="dashboard-topblock"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.6 }}
        >
          <p className="dashboard-topblock-data">₹{organisationData.total_loan_approved || "0"}</p>
          <label className="dashboard-topblock-label">Total Approved Loan</label>
        </motion.div>
        <motion.div
          className="dashboard-topblock"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.7 }}
        >
          <p className="dashboard-topblock-data">₹{organisationData.pending_loan_amount || "0"}</p>
          <label className="dashboard-topblock-label">Pending Loan Amount</label>
        </motion.div>
        <motion.div
          className="dashboard-topblock"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: 0.7 }}
        >
          <p className="dashboard-topblock-data">{organisationData.pending_loans_approvals || "0"}</p>
          <label className="dashboard-topblock-label">Pending Loan Approvals</label>
        </motion.div>
      </section>

      <section className="dashboard-paymentsection">
        <header className="dashboard-paymentssection-header">
          Savings Payment Status - {new Date().toLocaleDateString("en-us", { month: "long" }) + " " + new Date().getFullYear()}
        </header>
        <main>
          <header className="dashboard-payments-buttonsection">
            <button
              className={statusOption === "pending" ? "dashboard-payments-button-active" : "dashboard-payments-button"}
              onClick={() => setStatusOption("pending")}
            >
              Pending
            </button>
            <button
              className={statusOption === "completed" ? "dashboard-payments-button-active" : "dashboard-payments-button"}
              onClick={() => setStatusOption("completed")}
            >
              Completed
            </button>
          </header>
          <main className="dashboard-payments-body">
            {statusOption === "completed" ? (
              completedUserData?.length !== 0 ? (
                completedUserData?.map((compdata) => {
                  return (
                    <div className="dashboard-payments-body-content">
                      <img src={compdata.imageUrl} />
                      <p>
                        {compdata.first_name} {compdata.last_name}
                      </p>
                      <span>Completed</span>
                    </div>
                  );
                })
              ) : (
                <p className="dashboard-payments-body-nodata">No Completed Payments</p>
              )
            ) : pendingUserData?.length !== 0 ? (
              pendingUserData?.map((penddata) => {
                return (
                  <div className="dashboard-payments-body-content dashboard-payments-body-pendingcontent ">
                    <img src={penddata.imageUrl} />
                    <p>
                      {penddata.first_name} {penddata.last_name}
                    </p>
                    <span>Due in {10 - new Date().getDate()} days</span>
                  </div>
                );
              })
            ) : (
              <p className="dashboard-payments-body-nodata">No Pending Payments</p>
            )}
          </main>
        </main>
      </section>
    </div>
  );
}

export default Dashboard;
