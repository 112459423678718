import React, { useEffect, useMemo, useState } from "react";
import "./loancontainer.css";
import { toast } from "react-toastify";
import axiosInstance from "../../../../../helpers/axiosInstance";
import AuthorizedContent from "../../../../../shared/AuthorizedContent";
import FlipLoader from "../../../../../shared/fliploader/FlipLoader";
import { ReactComponent as ArrowIcon } from "../../../../../assets/signin/right.svg";
import { ReactComponent as TrashIcon } from "../../../../../assets/transactions/mdi_trash-can-empty.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/signin/delete.svg";
import { ReactComponent as ProfileIcon } from "../../../../../assets/navbar/profile.svg";
import { ReactComponent as FilterIcon } from "../../../../../assets/filter.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/plus.svg";
import useAuth from "../../../../../hooks/useAuth";
import Switch from "react-switch";
import { filterLoanID } from "../../../../../helpers/filterLoanId";
import { AnimatePresence, motion } from "framer-motion";

function LoanContainer(props) {
  const [transactionList, setTransactionList] = useState([]);
  const [repaymentList, setRepaymentList] = useState([]);
  const [transactionMetdata, setTransactionMetdata] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateloading, setUpdateLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRepaymentModal, setShowRepaymentModal] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [selectedRepayment, setselectedRepayment] = useState(null);
  const [showFilterModal, setshowFilterModal] = useState(false);
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState(0);
  const [term, setTerm] = useState(0);
  const [interest, setInterest] = useState(0);
  const [repayment_date, setRepaymentDate] = useState(new Date());
  const [repayment_amount, setRepaymentAmount] = useState(0);
  const [repayment_term, setRepaymentTerm] = useState(0);
  const [repayment_interest, setRepaymentInterest] = useState(0);
  const [repayment_comment, setRepaymentComment] = useState(null);
  const [active, setActive] = useState(true);
  const [comment, setComment] = useState(null);
  const [sanction_date, setSanctionDate] = useState(new Date());
  const [end_date, setEndDate] = useState(new Date(new Date().setMonth() + 3));
  const { organisation, auth } = useAuth();

  const [filtermemberid, setFiltermemberid] = useState("all");
  const [filteractive, setFilteractive] = useState("all");

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialData = () => {
    if (auth.user.role === "member" || props.parent === "details") {
      getTransactionDetails();
    } else {
      getAllTransactionDetails();
      getAllMembersList();
    }
  };

  const getTransactionDetails = () => {
    axiosInstance
      .get(`/api/loans/${organisation._id}/gettransactionbyid/${props.selectedMember?._id}?active=${filteractive}`)
      .then((response) => {
        setTransactionList(response.data.data);
        setTransactionMetdata(response.data.metadata);
        setLoading(false);
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get saving transactions details. Try again!");
        console.log(error);
      });
  };

  const getRepaymentDetails = () => {
    axiosInstance
      .get(`/api/repayment/${organisation._id}/getbyloan/${selectedDetail?._id}`)
      .then((response) => {
        setRepaymentList(response.data.data);
        // setLoading(false);
        console.log(response.data);
      })
      .catch((error) => {
        // setLoading(false);
        toast("Unable to get repayment transactions details. Try again!");
        console.log(error);
      });
  };

  useMemo(() => {
    if (selectedDetail) getRepaymentDetails();
  }, [selectedDetail]);

  const getAllTransactionDetails = () => {
    axiosInstance
      .get(`/api/loans/${organisation._id}/getalltransactions?member_id=${filtermemberid || ""}&active=${filteractive}`)
      .then((response) => {
        setTransactionList(response.data.data);
        setTransactionMetdata(response.data.metadata);
        setLoading(false);
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get saving transactions details. Try again!");
        console.log(error);
      });
  };
  const getAllMembersList = () => {
    axiosInstance
      .get(`/api/member/${organisation._id}/getallminimalmembers`)
      .then((response) => {
        setMembersList(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get members details. Try again!");
        console.log(error);
      });
  };

  const handleDelete = (id) => {
    setLoading(true);
    axiosInstance
      .delete(`/api/loans/${organisation._id}/deletetransaction/${id}`)
      .then((response) => {
        setLoading(false);
        toast("Member transaction deleted successfully");
        getInitialData();
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get saving transactions details. Try again!");
        console.log(error);
      });
  };

  const checkRequiredFields = () => {
    return (
      date &&
      typeof amount === "number" &&
      amount >= 5000 &&
      amount <= 30000 &&
      term >= 3 &&
      typeof term === "number" &&
      typeof interest === "number" &&
      sanction_date &&
      end_date
    );
  };

  const checkRepaymentRequiredFields = () => {
    return date && typeof amount === "number" && typeof term === "number" && typeof interest === "number";
  };

  const handleSubmit = () => {
    console.log(checkRequiredFields());
    if (!checkRequiredFields()) {
      toast("Please fill all the required fields.");
    } else {
      setLoading(true);
      setShowModal(false);
      axiosInstance
        .post(
          `/api/loans/${organisation._id}/createtransaction/${
            (auth.user.role === "admin" || auth.user.role === "super-admin") && props.parent !== "details"
              ? selectedMember
              : props.selectedMember?._id
          }`,
          {
            amount: parseInt(amount),
            date: Date.parse(date),
            term,
            interest,
            comment,
            sanction_date: Date.parse(sanction_date),
            end_date: Date.parse(end_date),
          }
        )
        .then((response) => {
          toast("Member transaction added successfully");
          getInitialData();
          console.log(response.data);
        })
        .catch((error) => {
          setLoading(false);
          toast("Unable to add saving transaction details. Try again!");
          console.log(error);
        });
    }
  };
  const handleChange = (checked) => {
    setUpdateLoading(true);

    axiosInstance
      .post(`/api/loans/${organisation._id}/changeloanstatus/${selectedDetail?._id}`, {
        active: active,
      })
      .then((response) => {
        setShowDeleteModal(false);
        setUpdateLoading(false);
        toast("loan transaction closed successfully");
        const newTransaction = transactionList.map((item) => {
          if (item._id === selectedDetail._id) {
            item.active = checked;
            if (checked) {
              ++transactionMetdata.active_loan;
              --transactionMetdata.closed_loan;
            } else if (!checked) {
              --transactionMetdata.active_loan;
              ++transactionMetdata.closed_loan;
            }
          }
          return item;
        });
        console.log(newTransaction);
        setTransactionList(newTransaction);
        setSelectedDetail(null);
        getInitialData();
      })
      .catch((err) => {
        toast("Unable to close loan transaction! Please try again");
        setShowDeleteModal(false);
        setUpdateLoading(false);
      });
  };

  // const renderDetailModal = () => {
  //   let date = new Date(selectedDetail.date).toLocaleDateString("en-us", {
  //     weekday: "long",
  //     year: "numeric",
  //     month: "long",
  //     day: "numeric",
  //   });
  //   let sanction_date = new Date(
  //     selectedDetail.sanction_date
  //   ).toLocaleDateString("en-us", {
  //     weekday: "long",
  //     year: "numeric",
  //     month: "long",
  //     day: "numeric",
  //   });
  //   let end_date = new Date(selectedDetail.end_date).toLocaleDateString(
  //     "en-us",
  //     { weekday: "long", year: "numeric", month: "long", day: "numeric" }
  //   );

  //   return (
  //     <section className="loanscontainer-detailmodal-container">
  //       <div className="loanscontainer-detailmodal-content">
  //         <header className="loanscontainer-detailmodal-header">
  //           <ArrowIcon
  //             className="loanscontainer-modal-lefticon"
  //             onClick={() => setSelectedDetail(null)}
  //           />
  //           Loan Transaction Details
  //         </header>
  //         <main className="loanscontainer-detailmodal-body">
  //           <div className="loanscontainer-detailmodal-form">
  //             <label>Loan Id</label>
  //             <p>{filterLoanID(selectedDetail._id, selectedDetail.date)}</p>
  //           </div>
  //           <div className="loanscontainer-detailmodal-form">
  //             <label>Amount</label>
  //             <p>{selectedDetail.amount}</p>
  //           </div>
  //           <div className="loanscontainer-detailmodal-form">
  //             <label>Date</label>
  //             <p>{date}</p>
  //           </div>
  //           <div className="loanscontainer-detailmodal-form">
  //             <label>Term</label>
  //             <p>{selectedDetail.term}</p>
  //           </div>
  //           <div className="loanscontainer-detailmodal-form">
  //             <label>Interest</label>
  //             <p>{selectedDetail.interest}</p>
  //           </div>
  //           <div className="loanscontainer-detailmodal-form">
  //             <label>Pending_loan</label>
  //             <p>{selectedDetail.pending_amount}</p>
  //           </div>
  //           <div className="loanscontainer-detailmodal-form">
  //             <label>Total Amount</label>
  //             <p>{selectedDetail.total_amount}</p>
  //           </div>
  //           <div className="loanscontainer-detailmodal-form">
  //             <label>Sanctioned Date</label>
  //             <p>{sanction_date}</p>
  //           </div>
  //           <div className="loanscontainer-detailmodal-form">
  //             <label>Loan Closing Date</label>
  //             <p>{end_date}</p>
  //           </div>
  //           <div className="loanscontainer-detailmodal-form">
  //             <label>Remarks</label>
  //             <p>{selectedDetail.comment || "-"}</p>
  //           </div>
  //           <AuthorizedContent roles={["super-admin", "admin"]}>
  //             <div className="loanscontainer-detailmodal-form">
  //               <label>Loan Status</label>{" "}
  //               <Switch
  //                 onChange={handleChange}
  //                 checked={active}
  //                 offColor={"#c20000"}
  //                 disabled={updateloading}
  //               />
  //             </div>
  //           </AuthorizedContent>
  //         </main>
  //       </div>
  //     </section>
  //   );
  // };

  const renderDetailModal = () => {
    let final_date = new Date(selectedRepayment?.date).toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return (
      <section className="repaymentcontainer-detailmodal-container">
        <div className="repaymentcontainer-detailmodal-content">
          <header className="repaymentcontainer-detailmodal-header">
            <ArrowIcon className="repaymentcontainer-modal-lefticon" onClick={() => setselectedRepayment(null)} />
            Loan Repayment Details
          </header>
          <main className="repaymentcontainer-detailmodal-body">
            <div className="repaymentcontainer-detailmodal-form">
              <label>Repayment Id</label>
              <p>{filterLoanID(selectedRepayment?._id, selectedRepayment.date)}</p>
            </div>
            <div className="repaymentcontainer-detailmodal-form">
              <label>Loan Id</label>
              <p>{filterLoanID(selectedRepayment.loanId, selectedRepayment?.loan?.date || selectedRepayment.date)}</p>
            </div>
            <div className="repaymentcontainer-detailmodal-form">
              <label>Amount</label>
              <p>{selectedRepayment.amount}</p>
            </div>

            <div className="repaymentcontainer-detailmodal-form">
              <label>Date</label>
              <p>{final_date}</p>
            </div>
            <div className="repaymentcontainer-detailmodal-form">
              <label>Term</label>
              <p>
                {selectedRepayment.term}/{selectedRepayment.total_term}
              </p>
            </div>
            <div className="repaymentcontainer-detailmodal-form">
              <label>Interest</label>
              <p>{selectedRepayment.interest}</p>
            </div>
            <div className="repaymentcontainer-detailmodal-form">
              <label>Total Amount</label>
              <p>{selectedRepayment.amount + selectedRepayment.interest}</p>
            </div>
            <div className="repaymentcontainer-detailmodal-form">
              <label>Remarks</label>
              <p>{selectedRepayment.comment}</p>
            </div>
          </main>
        </div>
      </section>
    );
  };

  const handleRepaymentSubmit = () => {
    console.log(checkRepaymentRequiredFields());
    if (!checkRepaymentRequiredFields()) {
      toast("Please fill all the required fields.");
    } else {
      // setLoading(true);
      setShowRepaymentModal(false);
      axiosInstance
        .post(`/api/repayment/${organisation._id}/create`, {
          amount: parseInt(repayment_amount),
          date: Date.parse(repayment_date),
          term: parseInt(repayment_term),
          interest: parseInt(repayment_interest),
          loanId: selectedDetail._id,
          comment: repayment_comment,
          userId: selectedDetail.userId,
        })
        .then((response) => {
          toast("Loan repayment transaction added successfully");
          getRepaymentDetails();
          setSelectedDetail(null);
          console.log(response.data);
        })
        .catch((error) => {
          // setLoading(false);
          toast("Unable to add Loan repayment transaction details. Try again!");
          console.log(error);
        });
    }
  };
  const handleRepaymentDelete = (id) => {
    // setLoading(true);
    axiosInstance
      .delete(`/api/repayment/${organisation._id}/deleterepayment/${id}`)
      .then((response) => {
        // setLoading(false);
        toast("Member loan repayment transaction deleted successfully");
        getInitialData();
        getRepaymentDetails();
        setSelectedDetail(null);
        console.log(response.data);
      })
      .catch((error) => {
        // setLoading(false);
        toast("Unable to get saving transactions details. Try again!");
        console.log(error);
      });
  };

  const renderRepaymentModal = () => {
    return (
      <motion.section
        className="repaymentcontainer-modal-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.2 }}
      >
        <header className="repaymentcontainer-modal-header">
          <ArrowIcon className="repaymentcontainer-modal-lefticon" onClick={() => setShowRepaymentModal(false)} />
          Add Loan Repayment
        </header>
        <main className="repaymentcontainer-modal-body">
          {/* {props.parent !== "details" ? (
            <div className="repaymentcontainer-form">
              <label>Member</label>
              <select
                name="date"
                onChange={({ target }) => {
                  console.log(target.value);
                  setSelectedMember(target.value);
                  getLoanDetails(target.value);
                }}
                value={selectedMember}
                required={true}
              >
                <option value={null}>Select </option>
                {membersList.map((member) => {
                  return (
                    <option value={member._id} key={member._id}>
                      {" "}
                      {member.full_name}{" "}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : null} */}
          <div className="repaymentcontainer-form">
            <label>Date</label>
            <input
              type="date"
              name="date"
              onChange={({ target }) => {
                setRepaymentDate(target.value);
              }}
              placeholder={date}
              value={repayment_date}
              required={true}
            />
          </div>
          {/* <div className="repaymentcontainer-form">
            <label>Loan ID</label>
            <select
              name="date"
              onChange={({ target }) => {
                setLoanID(target.value);
              }}
              disabled={!loansList.length}
              value={loanID}
              required={true}
            >
              <option value={null}>Select </option>
              {loansList.map((loans) => {
                return (
                  <option value={loans._id} key={loans._id}>
                    {" "}
                    {filterLoanID(loans._id, loans.date)}{" "}
                  </option>
                );
              })}
            </select>
          </div> */}
          <div className="repaymentcontainer-form">
            <label>Amount</label>
            <input
              type="text"
              name="amount"
              onChange={({ target }) => {
                setRepaymentAmount(parseInt(target.value));
              }}
              required={true}
            />
          </div>
          <div className="repaymentcontainer-form">
            <label>Term</label>
            <select
              name="date"
              onChange={({ target }) => {
                setRepaymentTerm(parseInt(target.value));
              }}
              value={repayment_term}
              required={true}
            >
              <option value={null}>Select </option>
              {[...Array(selectedDetail.term)]
                .map((_, i) => i + 1)
                .map((element) => {
                  console.log(element);
                  return (
                    <option value={parseInt(element)} key={element}>
                      {element}
                    </option>
                  );
                })}
            </select>
            {/* <input
              type="text"
              name="term"
              onChange={({ target }) => {
                setRepaymentTerm(parseInt(target.value));
              }}
              required={true}
            /> */}
          </div>
          <div className="repaymentcontainer-form">
            <label>Interest Amount</label>
            <input
              type="text"
              name="interest"
              onChange={({ target }) => {
                setRepaymentInterest(parseInt(target.value));
              }}
              required={true}
              disabled={false}
            />
          </div>
          <div className="repaymentcontainer-form">
            <label>Remarks</label>
            <input
              type="text"
              name="interest"
              onChange={({ target }) => {
                setRepaymentComment(target.value);
              }}
              required={true}
            />
          </div>
        </main>
        <footer className="repaymentcontainer-modal-footer">
          {" "}
          <button className="repaymentcontainer-addbtn" onClick={handleRepaymentSubmit}>
            Submit
          </button>
        </footer>
      </motion.section>
    );
  };

  const renderModal = () => {
    return (
      <motion.section
        className="loanscontainer-modal-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.2 }}
      >
        <header className="loanscontainer-modal-header">
          <ArrowIcon className="loanscontainer-modal-lefticon" onClick={() => setShowModal(false)} />
          Add Loan Transaction
        </header>
        <main className="loanscontainer-modal-body">
          <AuthorizedContent roles={["admin", "super-admin"]}>
            {props.parent !== "details" ? (
              <div className="loanscontainer-form">
                <label>Member</label>
                <select
                  name="date"
                  onChange={({ target }) => {
                    console.log(target.value);
                    setSelectedMember(target.value);
                  }}
                  value={selectedMember}
                  required={true}
                >
                  <option value={null}>Select </option>
                  {membersList.map((member) => {
                    return (
                      <option value={member._id} key={member._id}>
                        {" "}
                        {member.full_name}{" "}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : null}
          </AuthorizedContent>
          <div className="loanscontainer-form">
            <label>Date</label>
            <input
              type="date"
              name="date"
              onChange={({ target }) => {
                setDate(target.value);
              }}
              // placeholder={date}
              value={date}
              required={true}
            />
          </div>
          <div className="loanscontainer-form">
            <label>Amount</label>
            <input
              type="text"
              name="amount"
              onChange={({ target }) => {
                setAmount(parseInt(target.value));
              }}
              required={true}
            />
          </div>
          <div className="loanscontainer-form">
            <label>Term (in months)</label>
            <input
              type="text"
              name="term"
              onChange={({ target }) => {
                setTerm(parseInt(target.value));
              }}
              required={true}
            />
          </div>
          <div className="loanscontainer-form">
            <label>Interest Amount</label>
            <input
              type="text"
              name="interest"
              onChange={({ target }) => {
                setInterest(parseInt(target.value));
              }}
              required={true}
            />
          </div>
          <div className="loanscontainer-form">
            <label>Sanction Date</label>
            <input
              type="date"
              name="date"
              onChange={({ target }) => {
                setSanctionDate(target.value);
              }}
              // placeholder={sanction_date}
              value={sanction_date}
              required={true}
            />
          </div>
          <div className="loanscontainer-form">
            <label>End Date</label>
            <input
              type="date"
              name="date"
              onChange={({ target }) => {
                setEndDate(target.value);
              }}
              // placeholder={end_date}
              value={end_date}
              required={true}
            />
          </div>
          <div className="loanscontainer-form">
            <label>Remarks</label>
            <input
              type="text"
              name="date"
              onChange={({ target }) => {
                setComment(target.value);
              }}
              // placeholder={end_date}
              value={comment}
              required={true}
            />
          </div>
        </main>
        <footer className="loanscontainer-modal-footer">
          {" "}
          <button className="loanscontainer-addbtn" onClick={handleSubmit}>
            Submit
          </button>
        </footer>
      </motion.section>
    );
  };
  const renderDeleteModal = () => {
    return (
      <section className="loanscontainer-deletemodal-container">
        <div className="loanscontainer-deletemodal-content">
          <header className="loanscontainer-deletemodal-header">
            <TrashIcon className="loanscontainer-deletemodal-lefticon" />
            Are you sure you want to close this loan transaction?
          </header>

          <main className="loanscontainer-deletemodal-body">
            <section className="loanscontainer-form loanscontainer-delete-form">
              <label>ID : </label>
              <label>{filterLoanID(selectedDetail._id, selectedDetail.date)}</label>
            </section>

            <section className="loanscontainer-form loanscontainer-delete-form">
              <label>Name : </label>
              <label>
                <span>{selectedDetail.member.first_name} </span> <span>{selectedDetail.member.last_name}</span>
              </label>
            </section>
            <section className="loanscontainer-form loanscontainer-delete-form">
              <label>Total Amount : </label>
              <label>{selectedDetail.total_amount}</label>
            </section>
            <section className="loanscontainer-form loanscontainer-delete-form">
              <label>Pending Amount : </label>
              <label>{selectedDetail.pending_amount}</label>
            </section>
          </main>
          <footer className="loanscontainer-delete-footer">
            <button
              className="loanscontainer-addbtn loanscontainer-delete-cancelfooterbtn "
              onClick={() => {
                setActive(!active);
                setShowDeleteModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="loanscontainer-addbtn loanscontainer-delete-deletefooterbtn"
              onClick={() => {
                handleChange();
              }}
            >
              Submit
            </button>
          </footer>
        </div>
      </section>
    );
  };

  const rendrFilterModal = () => {
    return (
      <section className="loanscontainer-filtermodal-container">
        <div className="loanscontainer-filtermodal-content">
          <header className="loanscontainer-detailmodal-header">
            <ArrowIcon
              className="loanscontainer-modal-lefticon"
              onClick={() => {
                setshowFilterModal(false);
              }}
            />
            Filters
          </header>

          <main className="loanscontainer-filtermodal-body">
            <AuthorizedContent roles={["super-admin", "admin"]}>
              <div className="loanscontainer-form">
                <label>Users</label>
                <select
                  value={filtermemberid}
                  onChange={({ target }) => {
                    setFiltermemberid(target.value);
                  }}
                >
                  <option value={"all"}>All </option>
                  {membersList.map((member) => {
                    return (
                      <option value={member._id} key={member._id}>
                        {" "}
                        {member.full_name}{" "}
                      </option>
                    );
                  })}
                </select>
              </div>
            </AuthorizedContent>
            <div className="loanscontainer-form">
              <label>Status</label>
              <select
                value={filteractive}
                onChange={({ target }) => {
                  setFilteractive(target.value);
                }}
              >
                <option value={"all"}>All</option>
                <option value={true}>Active</option>
                <option value={false}>Closed</option>
              </select>
            </div>
          </main>
          <footer className="loanscontainer-filter-footer">
            <button
              className="loanscontainer-addbtn loanscontainer-filter-footerbtn"
              onClick={() => {
                setFiltermemberid("all");
                setFilteractive("all");
              }}
            >
              Clear
            </button>
            <button
              className="loanscontainer-addbtn loanscontainer-filter-footerbtn"
              onClick={() => {
                setLoading(true);
                getInitialData();
                setshowFilterModal(false);
              }}
            >
              Apply
            </button>
          </footer>
        </div>
      </section>
    );
  };
  let final_applied_date = new Date(selectedDetail?.date).toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  let final_sanction_date = new Date(selectedDetail?.sanction_date).toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  let final_end_date = new Date(selectedDetail?.end_date).toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <>
      {showDeleteModal ? renderDeleteModal() : null}
      <AnimatePresence>{showModal ? renderModal() : null}</AnimatePresence>
      <AnimatePresence>{showRepaymentModal ? renderRepaymentModal() : null} </AnimatePresence>
      {selectedRepayment ? renderDetailModal() : null}
      {showFilterModal ? rendrFilterModal() : null}
      <div className="loanscontainer-header">
        {props.parent !== "details" ? (
          <h3
            className="loanscontainer-heading"
            onClick={() => {
              setSelectedDetail(null);
              setRepaymentList([]);
            }}
          >
            {" "}
            Loans
            {selectedDetail ? (
              <>
                {" "}
                <ArrowIcon className="loanscontainer-modal-lefticon" onClick={() => setSelectedDetail(null)} />{" "}
                {filterLoanID(selectedDetail._id, selectedDetail.date)}
              </>
            ) : null}
          </h3>
        ) : null}
        {!selectedDetail ? (
          <>
            <button className="loanscontainer-filterbtn" onClick={() => setshowFilterModal(true)}>
              <FilterIcon />
            </button>
            <AuthorizedContent roles={["super-admin", "admin"]}>
              <button className="loanscontainer-addiconbtn" onClick={() => setShowModal(true)}>
                <PlusIcon />
              </button>
            </AuthorizedContent>
          </>
        ) : null}
      </div>
      {selectedDetail ? (
        <section className="loanscontainer-wrapper">
          <div className="loanscontainer-detailmodal-content">
            {/* <header className="loanscontainer-detailmodal-header">
              <ArrowIcon
                className="loanscontainer-modal-lefticon"
                onClick={() => setSelectedDetail(null)}
              />
              Loan Transaction Details
            </header> */}
            {props.parent !== "details" && auth.user.role !== "member" ? (
              <p className=" repaymentcontainer-listleft-user">
                <ProfileIcon /> {selectedDetail.member.first_name} {selectedDetail.member.last_name}
              </p>
            ) : null}
            <main className="loanscontainer-detailmodal-body">
              <div className="loanscontainer-detailmodal-form">
                <label>Loan Id</label>
                <p>{filterLoanID(selectedDetail._id, selectedDetail.date)}</p>
              </div>
              <div className="loanscontainer-detailmodal-form">
                <label>Amount</label>
                <p>{selectedDetail.amount}</p>
              </div>
              <div className="loanscontainer-detailmodal-form">
                <label>Applied Date</label>
                <p>{final_applied_date}</p>
              </div>
              <div className="loanscontainer-detailmodal-form">
                <label>Term</label>
                <p>{selectedDetail.term}</p>
              </div>
              <div className="loanscontainer-detailmodal-form">
                <label>Interest</label>
                <p>{selectedDetail.interest}</p>
              </div>
              <div className="loanscontainer-detailmodal-form">
                <label>Total Interest Paid</label>
                <p>{selectedDetail.total_interest_paid || 0}</p>
              </div>
              <div className="loanscontainer-detailmodal-form">
                <label>Pending Loan</label>
                <p>{selectedDetail.pending_amount}</p>
              </div>
              <div className="loanscontainer-detailmodal-form">
                <label>Total Amount</label>
                <p>{selectedDetail.total_amount}</p>
              </div>
              <div className="loanscontainer-detailmodal-form">
                <label>Sanctioned Date</label>
                <p>{final_sanction_date}</p>
              </div>
              <div className="loanscontainer-detailmodal-form">
                <label>Loan Closing Date</label>
                <p>{final_end_date}</p>
              </div>{" "}
              <div className="loanscontainer-detailmodal-form">
                <label>Monthly EMI</label>
                <p>{selectedDetail.monthly_emi}</p>
              </div>
              <div className="loanscontainer-detailmodal-form">
                <label>Remarks</label>
                <p>{selectedDetail.comment || "-"}</p>
              </div>
              <AuthorizedContent roles={["super-admin", "admin"]}>
                <div className="loanscontainer-detailmodal-form">
                  <label>Loan Status</label>{" "}
                  <Switch
                    onChange={(checked) => {
                      console.log(checked);
                      setActive(checked);
                      if (!checked) setShowDeleteModal(true);
                    }}
                    checked={active}
                    offColor={"#c20000"}
                    disabled={updateloading || !selectedDetail.active}
                  />
                </div>
              </AuthorizedContent>
            </main>
          </div>
          <section>
            <header className="loanscontainer-detailmodal-header">
              Loan Repayments{" "}
              {selectedDetail?.active ? (
                <AuthorizedContent roles={["super-admin", "admin"]}>
                  <button className="repaymentcontainer-addiconbtn" onClick={() => setShowRepaymentModal(true)}>
                    <PlusIcon />
                  </button>
                </AuthorizedContent>
              ) : null}
            </header>
            <div className="repaymentcontainer-wrapper">
              {loading ? (
                <div className="repaymentcontainer-loader">
                  <FlipLoader />
                </div>
              ) : !repaymentList?.length ? (
                <p>There are no repayment transactions.</p>
              ) : (
                <div className="repaymentcontainer-listwrapper">
                  {/* <motion.header
                    className="repaymentcontainer-listwrapper-header"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.1 }}
                  >
                    <label className="repaymentcontainer-listwrapper-headerleft">
                      Entries : <span>{transactionMetadata.count}</span>
                    </label>
                    <label className="repaymentcontainer-listwrapper-headerright">
                      Pending Loan Amount :{" "}
                      <span>₹ {transactionMetadata.pending_loan}</span>{" "}
                    </label>
                  </motion.header> */}
                  {repaymentList?.map((transaction, idx) => {
                    let date = new Date(transaction.date).toLocaleDateString("en-us", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    });
                    return (
                      <motion.div
                        className="repaymentcontainer-list"
                        key={idx}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <div className="repaymentcontainer-listleft">
                          <p className="repaymentcontainer-listleft-date">{date}</p>
                          {props.parent !== "details" && auth.user.role !== "member" ? (
                            <p className=" repaymentcontainer-listleft-user">
                              <ProfileIcon /> {transaction.member.first_name} {transaction.member.last_name}
                            </p>
                          ) : null}
                          <p
                            className="repaymentcontainer-listleft-id"
                            onClick={() => {
                              setselectedRepayment(transaction);
                            }}
                          >
                            {filterLoanID(transaction._id, transaction.date)}
                          </p>
                        </div>
                        <label
                          className={
                            auth.user.role === "super-admin" || auth.user.role === "admin"
                              ? "repaymentcontainer-listrighttotal"
                              : "repaymentcontainer-listrighttotal repaymentcontainer-listrightmember"
                          }
                        >
                          ₹ {transaction.amount + transaction.interest}
                        </label>

                        <AuthorizedContent roles={["super-admin", "admin"]}>
                          <div
                            className={
                              !selectedDetail?.active
                                ? "repaymentcontainer-listrightdelete repaymentcontainer-listrightdeletedisabled"
                                : "repaymentcontainer-listrightdelete"
                            }
                            onClick={() => {
                              if (selectedDetail?.active) handleRepaymentDelete(transaction._id);
                            }}
                          >
                            <DeleteIcon />
                          </div>
                        </AuthorizedContent>
                      </motion.div>
                    );
                  })}
                </div>
              )}
            </div>
          </section>
        </section>
      ) : (
        <div className="loanscontainer-wrapper">
          {loading ? (
            <div className="loanscontainer-loader">
              <FlipLoader />
            </div>
          ) : !transactionList?.length ? (
            <p>There are no loan transactions.</p>
          ) : (
            <div className="loanscontainer-listwrapper">
              <motion.header
                className="loanscontainer-listwrapper-header"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.1 }}
              >
                <label className="loanscontainer-listwrapper-headerleft">
                  <label>
                    Active : <span>{transactionMetdata.active_loan}</span>
                  </label>{" "}
                  <label>
                    Closed : <span>{transactionMetdata.closed_loan}</span>
                  </label>
                </label>
                <label className="loanscontainer-listwrapper-headerright">
                  Total Loan Amount : <span>₹ {transactionMetdata.total_loan}</span>{" "}
                </label>
              </motion.header>
              {transactionList?.map((transaction, idx) => {
                let date = new Date(transaction.date).toLocaleDateString("en-us", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                });
                return (
                  <motion.div
                    className="loanscontainer-list"
                    key={idx}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <div className="loanscontainer-listleft">
                      <p className="loanscontainer-listleft-date">{date}</p>
                      {props.parent !== "details" && auth.user.role !== "member" ? (
                        <p className=" loanscontainer-listleft-user">
                          <ProfileIcon /> {transaction.member.first_name} {transaction.member.last_name}
                        </p>
                      ) : null}

                      <p
                        className="loanscontainer-listleft-id"
                        onClick={() => {
                          setSelectedDetail(transaction);
                          setActive(transaction.active);
                        }}
                      >
                        {filterLoanID(transaction._id, transaction.date)}{" "}
                        <span
                          className={
                            transaction.active
                              ? "loanscontainer-listleft-status loanscontainer-listleft-status-open"
                              : "loanscontainer-listleft-status loanscontainer-listleft-status-closed"
                          }
                        >
                          <span></span>
                          {transaction.active ? "Active" : "Closed"}
                        </span>{" "}
                      </p>
                    </div>
                    <label
                      className={
                        auth.user.role === "super-admin" || auth.user.role === "admin"
                          ? "loanscontainer-listrighttotal"
                          : "loanscontainer-listrighttotal loanscontainer-listrightmember"
                      }
                    >
                      ₹ {transaction.total_amount}
                    </label>

                    <AuthorizedContent roles={["super-admin", "admin"]}>
                      <div className={"loanscontainer-listrightdelete "} onClick={() => handleDelete(transaction._id)}>
                        <DeleteIcon />
                      </div>
                    </AuthorizedContent>
                  </motion.div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default LoanContainer;
